import { useEffect, useState } from 'react';
import { checkWindow } from '@vfit/shared/data-access';
import { dehydrate } from 'react-query';
import { CoverageToolFromApp, Layout } from '@vfit/consumer/components';
import { IPage, usePage, useTranslate, isAppMock } from '@vfit/consumer/data-access';

import {
  prefetchStaticPathsConsumer,
  prefetchDataConsumer,
  prefetchPagesConsumer,
} from '@vfit/cms';

export default function PageBuilder() {
  const { translate, initDefaultMultiLang } = useTranslate();
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = checkWindow() && new URLSearchParams(querystringParams);
  const isApp = checkWindow() && urlParams.get('app');
  const { page } = usePage();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    translate();
    setLoading(false);
  }, []);

  if (loading) {
    initDefaultMultiLang();
    return null;
  }
  return (
    <>
      <Layout isApp={!!isApp} />
      {page.params.layout === 'HUB' && isAppMock() && isApp && <CoverageToolFromApp />}
    </>
  );
}

export async function getStaticPaths() {
  const allPaths = await prefetchStaticPathsConsumer();
  return {
    paths: allPaths,
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  const contextPath = params?.path?.join('/') || '';
  const allPages = await prefetchPagesConsumer();
  // Find current page
  let page: IPage = null;
  Object.keys(allPages).forEach((pK) => {
    const lPage = allPages[pK];
    if (lPage.params?.path?.join('/') === contextPath) page = lPage;
  });
  // prefetch data for page
  const queryClient = await prefetchDataConsumer(page);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      page,
    },
  };
}
